b<template>
  <div class="transdata_wrap radius-8">
    <div class="head_wrap p-t-b-15 p-l-r-30 flex justify-between">
      <div class="left">客流数据</div>
      <div class="right">
        <el-switch
          @change="changeDate"
          v-model="dataType"
          active-text="月"
          inactive-text="日"
        >
        </el-switch>
      </div>
    </div>
    <div class="con_wrap">
      <div :id="idName" :style="{ height: height, width: width }" />
    </div>
  </div>
</template>

<script>
import api from "@/api";
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
export default {
  props: {
    idName: {
      type: String,
      default: "passengerFlow",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "400px",
    },
    pieData: { Object, default: Array },
  },
  data() {
    return {
      chart: null,
      dataType: false,
      chartData: [],
    };
  },

  watch: {
    chartData: {
      handler(val) {
        if (!this.$utils.isEmpty(val)) {
          this.initChart();
        }
      },
      deep: true,
    },
  },
  created() {
    this.getTransactionStatisticsPassengerFlowHour();
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    // 初始化图表
    initChart() {
      var chartDom = document.getElementById(this.idName);
      this.chart = echarts.init(chartDom, "macarons");
      this.setOptions(this.chartData);
    },
    // 配置数据
    setOptions() {
      let option = {
        tooltip: {
          trigger: "axis",
        },
        // legend: {
        //   data: ["在场人数"],
        // },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.chartData.map((x) => {
            return x.title;
          }),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "人数",
            type: "line",
            data: this.chartData.map((x) => {
              return x.number;
            }),
            smooth: false,
            symbolSize: 10,
          },
        ],
      };
      this.chart.setOption(option);
    },
    // 日期选择
    changeDate(e) {
      console.log(e);
      if (e) {
        console.log(1111);
        this.getTransactionStatisticsPassengerFlowMonth();
      } else {
        this.getTransactionStatisticsPassengerFlowHour();
        console.log(22222);
      }
    },
    // 获取日数据
    async getTransactionStatisticsPassengerFlowHour() {
      let result = await api.common.get({
        apiName: "/system/index/transactionStatisticsPassengerFlowHour",
      });
      this.chartData = result.data;
    },
    // 获取月数据
    async getTransactionStatisticsPassengerFlowMonth() {
      let result = await api.common.get({
        apiName: "/system/index/transactionStatisticsPassengerFlowMonth",
      });
      this.chartData = result.data;
    },
  },
};
</script>
